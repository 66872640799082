/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.shado {
  box-shadow: 0 0 5px 0.5px rgb(126, 125, 125);
}
/* -------------------- navbar ---------------------- */
/* Add this CSS to your global stylesheet or styled-components */
.navbar-content .active {
  position: relative;
}

.navbar-content .active::after {
  content: '';
  position: absolute;
  bottom: -2px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 8px); /* Adjust based on your link padding */
  height: 2px; /* Height of underline */
  background-color: #3182ce; /* Active link underline color */
}

.mobile-menu .active::after {
  content: '';
  position: absolute;
  bottom: -4px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 12px; /* Width of arrow */
  height: 12px; /* Height of arrow */
  border-top: 2px solid #3182ce; /* Arrow color */
  border-right: 2px solid #3182ce; /* Arrow color */
  transform: rotate(45deg);
}

/* -------------------- navbar ---------------------- */
body,
html {
  overflow-x: hidden;
}
